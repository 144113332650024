import React, { useEffect, useState } from "react";
import { View, StyleSheet, Dimensions, Alert } from "react-native";
import { Button, Header, CheckBox } from "react-native-elements";
import { StatusBar } from "expo-status-bar";

import { CommonActions } from "@react-navigation/native";
import base_url from "../config";
import { Input } from "react-native-elements";

import { useContext } from "react";
import StuffinaboxContext from "./redux/something_context";

export default function ManageContactScreenEdit({ navigation, route }) {
  // // console.log("MANA");
  const { contact_id } = route.params;
  const { driver_id, update_driver_id } = useContext(StuffinaboxContext);
  const [name, setName] = useState(null);
  const [vilage, setVilage] = useState(null);
  const [phone, setPhone] = useState(null);

  const [comment, setComment] = useState(null);
  const [checked, setChecked] = useState(null);
  const [checkbox, setCheckBox] = useState(null);
  const [status, setStatus] = useState(null);
  useEffect(() => {
    fetch_pickup();
  }, [navigation, contact_id]);

  const remove_pickup = () => {
    fetch(base_url + `/api/v2/contact/${contact_id}` + "?token=" + driver_id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const resetAction = CommonActions.reset({
          index: 0,
          routes: [
            {
              name: "EditContact",
              params: { contact_id: null, key: route.params.key },
            },
          ],
        });
        navigation.dispatch(resetAction);
        navigation.navigate("Today");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetch_pickup = () => {
    fetch(base_url + `/api/v2/contact/${contact_id}` + "?token=" + driver_id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // // console.log("response_json", responseJson);
        setName(responseJson["full_name"]);
        setVilage(responseJson["vilage"]);
        setPhone(responseJson["cell_phone"]);
        setStatus(responseJson["status"]);
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const edit_contact = () => {
    const body = JSON.stringify({
      full_name: name,
      first_name: name,
      last_name: name,
      vilage: vilage,
      cell_phone: phone,
      comments: comment,
      status: status,
    });
    // // console.log("BODY", body);
    fetch(base_url + `/api/v2/contact/${contact_id}` + "?token=" + driver_id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // // console.log("Going to pick screen");
        navigation.navigate("Today");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <View style={{ flex: 1, flexDirection: "column", alignContent: "center" }}>
      <StatusBar hidden={true} />

      <Header
        placement="left"
        centerComponent={{ text: "TO", style: { color: "#fff" } }}
        rightComponent={
          <Button
            title="Delete"
            onPress={remove_pickup}
            color={"red"}
            buttonStyle={{ backgroundColor: "red" }}
          />
        }
      />

      <View>
        <Input
          label={"Name"}
          value={name}
          leftIcon={{ type: "fontisto", name: "person" }}
          style={styles}
          onChangeText={(value) => setName(value)}
        />
        <Input
          label={"Vilage"}
          // placeholder={old_vilage}
          // defaultValue={old_vilage}
          value={vilage}
          leftIcon={{ type: "fontisto", name: "holiday-village" }}
          style={styles}
          onChangeText={(value) => setVilage(value)}
        />
        <Input
          label={"Phone"}
          // defaultValue={old_phone}
          value={phone}
          leftIcon={{ type: "fontisto", name: "phone" }}
          style={styles}
          onChangeText={(value) => setPhone(value)}
        />
        <Input
          // defaultValue={old_comment}
          value={comment}
          leftIcon={{ type: "fontisto", name: "comment" }}
          style={styles}
          onChangeText={(value) => setComment(value)}
        />
        <Button
          title={"submit"}
          onPress={() => {
            edit_contact();
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  map: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height * 0.9,
  },
  button2: {
    alignItems: "center",
    marginTop: 20,
    backgroundColor: "#e4b9c2",
    padding: 10,
    paddingHorizontal: 10,
    borderRadius: 50,
    height: 100,
  },
});
