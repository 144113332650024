import React, { useEffect, useState } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import { Button, Header, CheckBox } from "react-native-elements";
import { StatusBar } from "expo-status-bar";

import { CommonActions } from "@react-navigation/native";
import base_url from "../../config";
import { Input } from "react-native-elements";

import { useContext } from "react";
import StuffinaboxContext from "../redux/something_context";

export default function ManagePickupScreen2({ navigation, route }) {
  // console.log("ContactInfoScreen");
  const {
    contact_id,
    old_name,
    old_vilage,
    old_phone,
    old_comment,
    old_checked,
  } = route.params;
  const { driver_id, update_driver_id } = useContext(StuffinaboxContext);
  const [name, setName] = useState(old_name);
  const [vilage, setVilage] = useState(old_vilage);
  const [phone, setPhone] = useState(old_phone);

  const [comment, setComment] = useState(old_comment);
  const [checked, setChecked] = useState(old_checked);
  const [checkbox, setCheckBox] = useState(old_checked);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      setChecked(old_checked);
    });
    return unsubscribe;
  }, [navigation]);

  const remove_pickup = () => {
    fetch(base_url + `/api/v2/pickup/${contact_id}` + "?token=" + driver_id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const resetAction = CommonActions.reset({
          index: 0,
          routes: [
            {
              name: "EditPickup",
              params: { contact_id: null, key: route.params.key },
            },
          ],
        });
        navigation.dispatch(resetAction);
        // console.log("Going to pick screen");
        navigation.navigate("Pickup");
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const newpickup = () => {
    // console.log("Pickup PARAMS", name);
    let n = name;
    let v = vilage;
    let p = phone;
    let c = comment;
    let ch = checked;

    if (name == undefined) {
      n = old_name;
    }
    if (vilage == undefined) {
      v = old_vilage;
    }
    if (phone == undefined) {
      p = old_phone;
    }
    if (comment == undefined) {
      c = old_comment;
    }
    if (checked == undefined) {
      ch = old_checked;
    }
    // console.log("CHECKED", ch);
    const body = JSON.stringify({
      full_name: n,
      first_name: n,
      last_name: n,

      vilage: v,
      cell_phone: p,
      comments: c,
      status: ch,
    });
    // console.log(body);
    fetch(base_url + `/api/v2/pickup/${contact_id}` + "?token=" + driver_id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const resetAction = CommonActions.reset({
          index: 0,
          routes: [
            {
              name: "EditPickup",
              params: { contact_id: null, key: route.params.key },
            },
          ],
        });
        navigation.dispatch(resetAction);
        // console.log("Going to pick screen");
        navigation.navigate("Pickup");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <View style={{ flex: 1, flexDirection: "column", alignContent: "center" }}>
      <StatusBar hidden={true} />

      <Header
        placement="left"
        centerComponent={{ text: "TO", style: { color: "#fff" } }}
        rightComponent={
          <Button
            title="Delete"
            onPress={remove_pickup}
            color={"red"}
            buttonStyle={{ backgroundColor: "red" }}
          />
        }
      />

      <View>
        <Input
          label={"Name"}
          defaultValue={old_name}
          // value={old_name}
          // value={old_name}
          leftIcon={{ type: "fontisto", name: "person" }}
          style={styles}
          onChangeText={(value) => setName(value)}
        />
        <Input
          label={"Vilage"}
          // placeholder={old_vilage}
          defaultValue={old_vilage}
          leftIcon={{ type: "fontisto", name: "holiday-village" }}
          style={styles}
          onChangeText={(value) => setVilage(value)}
        />
        <Input
          label={"Phone"}
          defaultValue={old_phone}
          leftIcon={{ type: "fontisto", name: "phone" }}
          style={styles}
          onChangeText={(value) => setPhone(value)}
        />
        <Input
          defaultValue={old_comment}
          leftIcon={{ type: "fontisto", name: "comment" }}
          style={styles}
          onChangeText={(value) => setComment(value)}
        />
        <CheckBox
          center
          title="Received"
          checked={checked}
          onPress={() => setChecked(!checked)}
        />
        <Button
          title={"submit"}
          onPress={() => {
            newpickup();
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  map: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height * 0.9,
  },
  button2: {
    alignItems: "center",
    marginTop: 20,
    backgroundColor: "#e4b9c2",
    padding: 10,
    paddingHorizontal: 10,
    borderRadius: 50,
    height: 100,
  },
});
