// import { ToastAndroid } from "react-native";

// export const showToast = (message) => {
//   ToastAndroid.show(message, ToastAndroid.SHORT);
// };

export const changeStatus = (
  navigation,
  base_url,
  token,
  contact_id,
  status,
  saveLocation,
  location
) => {
  if (status == 1) {
    saveLocation(token, base_url, location);
  }
  // 'https://127.0.0.1:5000/api/delivered?cell=001-570-918-0619&delivered=0'
  // console.log("changeStatus method: ", status);

  fetch(base_url + `/api/v2/contact/${contact_id}` + "?token=" + token, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ status: status.toString() }),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log("response object:", responseJson);

      // showToast("Status Updated!");
      // input.clear();
      navigation.navigate("Today");
    })
    .catch((error) => {
      console.error(error);
      // showToast("Something Went Wrong!");
    });
};

export const getVilages = (base_url, setLoading, setVilages, token) => {
  // console.log("vilages request");
  const url = base_url + "/api/v1/location/vilages?token=" + token;

  setLoading(true);
  fetch(url)
    .then((res) => res.json())
    .then((res) => {
      setVilages(res.vilages);
      // // console.log("Vilgaes", res.vilages);
    })
    .catch((error) => {
      // console.log("get vilages error", error);
    });
};

// TODO: change user to contact and user_id to contact id
export const saveLocation = (token, base_url, location) => {
  // console.log("LOCATkljdlfjs;alkdfj;aslfkjsdION", location);
  // location = {
  //   coords: {
  //     accuracy: 20,
  //     altitude: 1.061117780713645,
  //     altitudeAccuracy: 7.305134296417236,
  //     heading: 0,
  //     latitude: 37.6747046,
  //     longitude: 26.3476036,
  //     speed: 0,
  //   },
  //   mocked: false,
  //   timestamp: 1647800050227,
  // };

  fetch(base_url + "/api/locationview?token=" + token, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },

    body: JSON.stringify({
      data: location,
    }),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log("response object:", responseJson);
      // showToast("Status Updated!");
    })
    .catch((error) => {
      console.error("location error", error);
      // showToast("Something Wendsfsdkfs;dlkft Wrong!");
    });
};

export const getComment = (url_get_comment, setLoading) => {
  // console.log("get comment");

  // console.log(url_get_comment, setComment);
  setLoading(true);
  fetch(url_get_comment)
    .then((res) => res.json())
    .then((res) => {
      setComment(res.comment);
      setLoading(false);

      // console.log("Responce Comment", res.comment);
    })
    .catch((error) => {
      // console.log("comment error", error);
    });
};

export const changeVilageName = (
  navigation,
  base_url,
  user,
  selectedVilage
) => {
  // // console.log()
  if (selectedVilage == null || selectedVilage == "") {
    // showToast("Must Select or Create a New Vilage!");
  } else {
    fetch(
      base_url +
        "/api/vilages?user_id=" +
        user.user_id +
        "&vilage=" +
        selectedVilage,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: [],
        }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("response object:", responseJson);

        // showToast("Status Updated!");

        navigation.navigate("Today");
      })
      .catch((error) => {
        console.error(error);
        // showToast("Something Went Wrong!");
      });
  }
};

export const sendChangeComment = (navigation, base_url, user, comment) => {
  fetch(
    base_url + "/api/comment?user_id=" + user.user_id + "&comment=" + comment,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: [],
      }),
    }
  )
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log("response object:", responseJson);

      // showToast("Status Updated!");

      navigation.navigate("Today");
    })
    .catch((error) => {
      console.error(error);
      // showToast("Something Went Wrong!");
    });
};

export const sendChangeVilage = (navigation, user, base_url, picked_vilage) => {
  fetch(
    base_url +
      "/api/vilages?user_id=" +
      user.user_id +
      "&vilage=" +
      picked_vilage,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: [],
      }),
    }
  )
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log("response object:", responseJson);
      // showToast("Vilage Changed!");
      navigation.navigate("Today");
    })
    .catch((error) => {
      console.error(error);
      // showToast("Something Went Wrong!");
    });
};
