import React, { useState } from "react";
import { Header, Button } from "react-native-elements";
import { StyleSheet, Dimensions, SectionList } from "react-native";
import { View } from "react-native";
import { useContext } from "react";
import StuffinaboxContext from "./redux/something_context";

import { StatusBar } from "expo-status-bar";

import { ListItem } from "react-native-elements";
import base_url from "../config";
import { useFocusEffect } from "@react-navigation/native";
import Spinner from "react-native-loading-spinner-overlay";

export default function DeliveredScreen({ navigation, route }) {
  const { driver_id, update_driver_id } = useContext(StuffinaboxContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  useFocusEffect(
    React.useCallback(() => {
      makeRemoteRequest(); // page => 1

      return () => {};
    }, [navigation])
  );

  const makeRemoteRequest = () => {
    let params_auth = "&token=" + driver_id;
    const url = base_url + "/api/v2/contact?status=[1]" + params_auth;
    // console.log("Request URL Delivered: ", url);
    setLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        setData(res);
        setLoading(false);
        setRefreshing(false);
        setError(res.error || null);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const gotopackages = (item) => {
    navigation.navigate("ContactInfo", {
      user: item,
      packages: item.packages,
    });
  };
  const setTextColor = (item) => {
    // console.log('settext color');
    let ColorValue = "transparent";
    if (item.status == 0) {
      // console.log('delivered',item.delivered)
      ColorValue = "transparent";
    }
    if (item.status == 1) {
      ColorValue = "red";
    }
    if (item.status == 2) {
      ColorValue = "#4e89cc";
    }
    if (item.status == 3) {
      ColorValue = "green";
    }

    if (item.status == 4) {
      ColorValue = "yellow";
    }
    return ColorValue;
  };

  const renderItem = ({ item }) => (
    <ListItem bottomDivider onPress={() => gotopackages(item)}>
      <ListItem.Content
        style={{
          backgroundColor: setTextColor(item),
        }}
      >
        <ListItem.Title
          style={{
            fontWeight: "bold",
          }}
        >
          {item.name.first}
        </ListItem.Title>
        <ListItem.Subtitle>COST: {item.cost_raw}</ListItem.Subtitle>
        <ListItem.Subtitle>PHONE:{item.cell}</ListItem.Subtitle>
        <ListItem.Subtitle>COMMENT: {item.comment}</ListItem.Subtitle>
      </ListItem.Content>
      <ListItem.Chevron />
    </ListItem>
  );

  const onRefresh = () => {
    makeRemoteRequest();
  };

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <StatusBar hidden={true} />
      <Spinner visible={loading} textContent={"Loading..."} />

      <Header
        placement="left"
        // leftComponent={{
        //   // text: "Total: " + total,
        //   color: "#fff",
        // }}
        centerComponent={{
          text: "PICK A USER.",
          style: {
            color: "#fff",
          },
        }}
        rightComponent={{
          icon: "refresh",
          color: "#fff",
          onPress: () => makeRemoteRequest(),
        }}
      />
      <View style={{ flex: 1 }}>
        <SectionList
          sections={data}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          keyExtractor={(item, index) => item + index}
          renderItem={(item) => renderItem(item)}
          renderSectionHeader={({ section: { vilage } }) => (
            <Button
              title={vilage}
              buttonStyle={{
                backgroundColor: "red",
              }}
              onPress={() =>
                navigation.navigate("Manage Vilage", { vilage: vilage })
              }
            />
          )}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  map: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height * 0.9,
  },
  header: {
    fontSize: 32,
    backgroundColor: "#fff",
  },
  header: {
    fontSize: 32,
    backgroundColor: "#fff",
    alignContent: "center",
  },
});
