import React, { useState, useContext } from "react";

import {
  StyleSheet,
  Dimensions,
  Alert,
  TextInput,
  TouchableOpacity,
  View,
  ImageBackground,
} from "react-native";
import StuffinaboxContext from "./redux/something_context";
import IconMaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { Text } from "react-native-elements";
import Spinner from "react-native-loading-spinner-overlay";
import base_url from "../config";

import * as Location from "expo-location";

const background = require("../assets/currio_v1.png");

export default function LoginScreen(props) {
  const [cell_phone, setCellPhone] = useState("");
  const [pin_number, setPinNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const { driver_id, update_driver_id } = useContext(StuffinaboxContext);

  const request_to_login = () => {
    ("POSTING lover INFO");

    setLoading(true);
    // TODO: #4 fix the .variable name situation bello
    let base_domain = base_url + "/api/v2/generate_token";

    let params =
      "?cell_phone=" +
      parseInt(cell_phone) +
      "&pin_number=" +
      parseInt(pin_number);
    fetch(base_domain + params, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return Promise.all([response.json(), response.status]);
      })
      .then((responseJson) => {
        let json_data = responseJson[0];

        let status = responseJson[1];
        setLoading(true);

        if (status == 200) {
          update_driver_id(json_data["token"]);
          setLoading(false);

          props.navigation.navigate("Today", {
            driver_id: json_data["token"],
            cell_phone: cell_phone,
            pin_number: pin_number,
          });
        } else {
          setLoading(false);
          // createAlert();
        }
      })
      .catch((error) => {
        // console.error("catcjing", error);
        setLoading(false);
      });
  };

  const startLocationTracking = async () => {
    let locationdata = await Location.startLocationUpdatesAsync(
      LOCATION_TRACKING,
      {
        accuracy: Location.Accuracy.Highest,
        timeInterval: 5000,
        distanceInterval: 0,
      }
    ).then((data) => {
      // console.log("data", data);
    });
  };

  const send_driver_loc = (longitude, latitude) => {
    const url = base_url + "/api/v1/driver/driverlocation";
    const params =
      "?driver_id=1&latitude=" + longitude + "&longitude=" + latitude;
    fetch(url + params, { method: "POST", data: { longitude, latitude } });
  };

  // // httpss://stackoverflow.com/questions/41795968/react-native-check-and-prompt-user-to-enable-network-gps
  // TaskManager.defineTask(LOCATION_TRACKING, async ({ data, error }) => {
  //   if (error) {
  //     console.log("LOCATION_TRACKING task ERROR:", error);
  //     return;
  //   }
  //   if (data) {
  //     const { locations } = data;
  //     let lat = locations[0].coords.latitude;
  //     let long = locations[0].coords.longitude;

  //     send_driver_loc(lat, long);
  //   }
  // });

  const goto_deliveries = () => {
    // startLocationTracking();

    request_to_login();
  };

  return (
    <ImageBackground
      resizeMode="cover"
      source={background}
      style={[styles.background, styles.container]}
    >
      <View style={styles.container}>
        <Spinner visible={loading} textContent={"Loading..."} />

        <View style={styles.wrapper}>
          <View style={styles.inputWrap}>
            <View style={styles.iconWrap}>
              <IconMaterialCommunityIcons name="cellphone" />
            </View>
            <TextInput
              // secureTextEntry
              style={styles.input}
              keyboardType="numeric"
              // value={cell_phone}
              placeholder="Cell Phone Number"
              underlineColorAndroid="transparent"
              onChangeText={(cell_phone) => setCellPhone(cell_phone)}
            />
          </View>
          <View style={styles.inputWrap}>
            <View style={styles.iconWrap}>
              <IconMaterialCommunityIcons name="numeric-9-box-multiple-outline" />
            </View>
            <TextInput
              // secureTextEntry
              style={styles.input}
              keyboardType="numeric"
              // value={pin_number}
              placeholder="Pin Number"
              underlineColorAndroid="transparent"
              onChangeText={(pin_number) => setPinNumber(pin_number)}
            />
          </View>
          <TouchableOpacity
            onPress={() =>
              goto_deliveries()
            } /*onPress={() => props.navigation.navigate('Graph')}*/
          >
            <View style={styles.button}>
              <Text style={styles.buttonText}>LOG IN</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  map: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height * 0.9,
  },

  button2: {
    alignItems: "center",
    marginTop: 20,

    backgroundColor: "#e4b9c2",
    padding: 10,
    paddingHorizontal: 10,
    borderRadius: 50,
    // width: 300,
    height: 40,
  },
  background: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
  wrapper: {
    paddingHorizontal: 15,
  },
  inputWrap: {
    // flex: 1,
    height: 40,
    marginVertical: 10,
    flexDirection: "row",
    // width: "0.2%",
    // alignItems: "center",
    // backgroundColor: "transparent"
  },
  input: {
    flex: 1,
    opacity: 1,
    textAlign: "center",
    paddingHorizontal: 10,
    backgroundColor: "#FFF",
    width: "1%",
  },
  iconWrap: {
    alignItems: "center",
    paddingHorizontal: 7,
    justifyContent: "center",
    backgroundColor: "white",
  },
  icon: {
    width: 20,
    height: 20,
  },
  button: {
    borderRadius: 10,
    marginVertical: 15,
    paddingVertical: 15,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#d73352",
  },
  buttonText: {
    fontSize: 18,
    color: "#FFF",
  },
  forgotPasswordText: {
    color: "#FFF",
    textAlign: "center",
    backgroundColor: "transparent",
  },
});
