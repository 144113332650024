import React, { useReducer } from "react";

const StuffinaboxContext = React.createContext();

const stuffinaboxReducer = (state, action) => {
  switch (action.type) {
    case "update_driver_id":
      return action.payload;

    default:
      return state;
  }
};

export const StuffinaboxProvider = ({ children }) => {
  const [driver_id, dispatch] = useReducer(
    stuffinaboxReducer,
    "No description set"
  );

  const update_driver_id = (new_driver_id) => {
    // // console.log(
    //   "%%%%%%%%%%%%%%%%%%%%%%%%UPDATING DESCRIPTION%%%%%%%%%%%%%%%%%%%%%%%%%%%%"
    // );
    // console.log("stuffinabox_context.js update_driver_id", new_driver_id);
    dispatch({ type: "update_driver_id", payload: new_driver_id });
  };

  return (
    <StuffinaboxContext.Provider
      value={{ driver_id: driver_id, update_driver_id }}
    >
      {children}
    </StuffinaboxContext.Provider>
  );
};

export default StuffinaboxContext;
