const button_list = [
  {
    button_name: "Delivered",
    status_code: 1,
    style: { backgroundColor: "red" },
  },
  {
    button_name: "No Answer",
    status_code: 2,
    style: { backgroundColor: "#4e89cc" },
  },
  {
    button_name: "Deliver Later",
    status_code: 3,
    style: { backgroundColor: "green" },
  },
  {
    button_name: "Wrong Number",
    status_code: 4,
    style: { backgroundColor: "black" },
  },
  {
    button_name: "Refusal of Delivery",
    status_code: 5,
    style: { backgroundColor: "orange" },
  },
  {
    button_name: "Other Day",
    status_code: 6,
    style: { backgroundColor: "gray" },
  },
  {
    button_name: "Pickup From Store",
    status_code: 7,
    style: { backgroundColor: "gray" },
  },
  {
    button_name: "No Money",
    status_code: 8,
    style: { backgroundColor: "gray" },
  },
  {
    button_name: "Nothing/Reset",
    status_code: 0,
    style: { backgroundColor: "transparent" },
    type: "outline",
  },
];

export default button_list;
