import React, { useState } from "react";
import { StatusBar } from "expo-status-bar";
import { Button, Header } from "react-native-elements";
import { StyleSheet, Dimensions, SectionList } from "react-native";
import { View } from "react-native";
import { ListItem, Icon } from "react-native-elements";
import { useFocusEffect } from "@react-navigation/native";
import base_url from "../config";
import { Linking } from "react-native";
import button_list from "../buttonStatusList";
import Spinner from "react-native-loading-spinner-overlay";
import { useContext } from "react";
import StuffinaboxContext from "./redux/something_context";
import * as DocumentPicker from "expo-document-picker";
import Ionicons from "@expo/vector-icons/Ionicons";

export default function TodayScreen({ navigation }) {
  const { driver_id, update_driver_id } = useContext(StuffinaboxContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  useFocusEffect(
    React.useCallback(() => {
      // console.log("ENTERING TODAY DELIVERIES SCREEN");

      makeRemoteRequest();

      return () => {
        // console.log("EXITING TODAY DELIVERIES SCREEN");
      };
    }, [navigation])
  );

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "*/*",
      multiple: true,
      copyToCacheDirectory: true,
    });

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(result),
    };
    const base_domain = base_url + "/api/v2/contact/upload?token=" + driver_id;

    fetch(base_domain, options).catch((error) => console.log(error));
    makeRemoteRequest();
  };

  const delete_vilage_check = (vilage) => {
    // Alert.alert(   "You are about to delete a vilage",   "Press ok if you are
    // sure",   [     {       text: "Cancel",       onPress: () =>
    // console.log("Cancel Pressed"),       style: "cancel",     },     { text:
    // "OK", onPress: () => console.log("OK") },   ],   { cancelable: false } );
    delete_vilage(vilage);
  };

  const delete_vilage = (vilage) => {
    console.log("VILAGE: ", vilage);
    const base_domain = base_url + "/api/v1/manager/remove_vilage";
    const query_params = "?vilage=" + vilage;
    const params_auth = "&token=" + driver_id;
    const url = base_domain + query_params + params_auth;
    setLoading(true);
    fetch(url, { method: "DELETE" })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        makeRemoteRequest();
      })
      .catch((error) => {
        console.log("WE HAVE AN ERROR");
      });
  };
  const makeRemoteRequest = () => {
    const base_domain = base_url + "/api/v2/contact";
    const query_params = "?status=[0,2,3,4,5,6,7,8,9,10]";
    const params_auth = "&token=" + driver_id;
    const url = base_domain + query_params + params_auth;
    setLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        // console.log("GETTING DATA");
        setData(res);
        setLoading(false);
        setRefreshing(false);
        setError(res.error || null);
        // console.log("DONE");
      })
      .catch((error) => {
        // console.log("WE HAVE AN ERROR");
        setLoading(false);

        setError(error);
      });
  };

  const gotopackages = (item) => {
    console.log("Leaving Today");
    // console.log(item)
    navigation.navigate("ContactInfo", {
      user: item,
      packages: item.packages,
      user_id: item.user_id,
    });
  };

  const statusWord2 = (item) => {
    var word = "No Comment";

    for (var i = 0; i < button_list.length; i++) {
      if (button_list[i].status_code == item.status) {
        word = button_list[i].button_name;
      }
    }
    return word;
  };

  const statusColor = (item) => {
    // the code you're looking for
    var ColorValue = "transparent";

    for (var i = 0; i < button_list.length; i++) {
      // look for the entry with a matching `code` value
      if (button_list[i].status_code == item.status) {
        ColorValue = button_list[i].style.backgroundColor;
      }
    }
    return ColorValue;
  };

  const goto_contact_info = (item) => {
    console.log("Leaving Today");
    console.log("NEW ID", item);

    navigation.navigate("EditContact", {
      user: item,
      contact_id: item.user_id,
    });
  };

  const renderItem = ({ item }) => {
    return (
      <ListItem bottomDivider onPress={() => gotopackages(item)}>
        <Icon
          size={50}
          name={"phone"}
          disabled={!item.has_phone_number}
          onPress={() => makeCall(item.cell)}
        />

        <ListItem.Content
          style={{
            backgroundColor: statusColor(item),
          }}
        >
          <ListItem.Title
            style={{
              fontWeight: "bold",
            }}
          >
            {item.name.first}
          </ListItem.Title>
          <ListItem.Subtitle>COST: {item.cost_raw}</ListItem.Subtitle>
          <ListItem.Subtitle>PHONE:{item.cell}</ListItem.Subtitle>
          <ListItem.Subtitle>COMMENT: {item.comment}</ListItem.Subtitle>
          <ListItem.Subtitle>STATUS: {statusWord2(item)}</ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Chevron />
        <Icon size={40} name={"edit"} onPress={() => goto_contact_info(item)} />
      </ListItem>
    );
  };

  const onRefresh = () => {
    makeRemoteRequest();
  };

  const makeCall = (tel) => {
    Linking.openURL(`tel:${tel}`);
  };

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <StatusBar hidden={true} />

      <Spinner visible={loading} textContent={"Loading..."} />
      <Header
        placement="center"
        leftComponent={{
          icon: "cloud-upload",
          color: "#fff",
          size: 40,
          onPress: () => pickDocument(),
        }}
        centerComponent={{
          text: "FOR DELIVERY",
          style: {
            fontSize: 20,
            color: "#fff",
          },
        }}
        rightComponent={{
          icon: "refresh",
          color: "#fff",
          onPress: () => onRefresh(),
        }}
      />
      <View
        style={{
          flex: 1,
        }}
      >
        <SectionList
          sections={data}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          keyExtractor={(item, index) => item + index}
          renderItem={(item) => renderItem(item)}
          renderSectionHeader={({ section: { vilage } }) => (
            <Button
              title={vilage}
              buttonStyle={{
                backgroundColor: "red",
              }}
              onPress={() =>
                navigation.navigate("Manage Vilage", { vilage: vilage })
              }
            />
          )}
        />

        <Button
          title={"Add Contact"}
          icon={<Ionicons name="add" size={32} color="green" />}
          onPress={() => navigation.navigate("CreateContact")}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  map: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height * 0.9,
  },
  header: {
    fontSize: 32,
    backgroundColor: "green",
    alignContent: "center",
  },
  header: {},
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
});
