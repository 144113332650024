import React, { useState } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import { Button, Header } from "react-native-elements";
import { StatusBar } from "expo-status-bar";

import base_url from "../../config";
import { Input } from "react-native-elements";

import { useContext } from "react";
import StuffinaboxContext from "../redux/something_context";
export default function ManagePickupScreen({ navigation }) {
  // console.log("ContactInfoScreen");
  const { driver_id, update_driver_id } = useContext(StuffinaboxContext);
  const [name, setName] = useState("");
  const [vilage, setVilage] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");

  const newpickup = () => {
    fetch(base_url + "/api/v2/pickup?token=" + driver_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        full_name: name,
        first_name: name,
        last_name: name,
        vilage: vilage,
        cell_phone: phone,
        comments: comment,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        navigation.navigate("Pickup");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <View style={{ flex: 1, flexDirection: "column" }}>
      <StatusBar hidden={true} />

      <Header
        placement="left"
        centerComponent={{ text: "TO", style: { color: "#fff" } }}
      />

      <View>
        <Input
          label="Name"
          placeholder="Name"
          leftIcon={{ type: "fontisto", name: "person" }}
          style={styles}
          onChangeText={(value) => setName(value)}
        />
        <Input
          placeholder="Vilage"
          leftIcon={{ type: "fontisto", name: "holiday-village" }}
          style={styles}
          onChangeText={(value) => setVilage(value)}
        />
        <Input
          placeholder="Phone Number"
          leftIcon={{ type: "fontisto", name: "phone" }}
          style={styles}
          onChangeText={(value) => setPhone(value)}
        />
        <Input
          placeholder="Comment"
          leftIcon={{ type: "fontisto", name: "comment" }}
          style={styles}
          onChangeText={(value) => setComment(value)}
        />
        <Button
          title={"submit"}
          onPress={() => {
            newpickup();
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  map: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height * 0.9,
  },
  button2: {
    alignItems: "center",
    marginTop: 20,
    backgroundColor: "#e4b9c2",
    padding: 10,
    paddingHorizontal: 10,
    borderRadius: 50,
    // width: 300,
    height: 100,
  },
});
