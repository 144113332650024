import React, { useEffect, useState } from "react";
import { View, StyleSheet, Dimensions, Alert } from "react-native";
import { ListItem, Text, Button, Header } from "react-native-elements";
import { StatusBar } from "expo-status-bar";
import * as Location from "expo-location";

import base_url from "../config";
import button_list from "../buttonStatusList";
import Barcode from "react-native-barcode-expo";

import {
  changeStatus,
  getVilages,
  saveLocation,
  changeVilageName,
} from "./services/web_requests";
import { useContext } from "react";
import StuffinaboxContext from "./redux/something_context";
export default function ContactInfo({ navigation, route }) {
  // // console.log("ContactInfoScreen");
  const { user } = route.params;

  const { driver_id, update_driver_id } = useContext(StuffinaboxContext);

  const [vilages, setVilages] = useState([]);
  const [comment, setComment] = useState("");
  const [selectedVilage, setselectedVilage] = useState("");

  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    //Update the state you want to be updated
    setComment(user.comment);
    setselectedVilage(null);
  }, [user]);

  useEffect(() => {
    getVilages(base_url, setLoading, setVilages, driver_id);
  }, [user]);

  useEffect(() => {
    // // console.log('Loading: ', loading)
    // // console.log("hello");
  }, [loading]);

  useEffect(() => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        setErrorMsg("Permission to access location was denied");
        return;
      }

      let location = await Location.getCurrentPositionAsync({
        accuracy: Location.Accuracy.Highest,
      });
      setLocation(location);
      // console.log("current location: " + location);
    })();
  }, []);

  const AlertChangeUserVilage = (navigation, base_url, user, selectedVilage) =>
    Alert.alert(
      "Vilage Change",
      "Are you sure you want to change the users vilage?",
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
        {
          text: "OK",
          onPress: () =>
            changeVilageName(navigation, base_url, user, selectedVilage),
        },
      ]
    );

  // const renderItem = ({ item }) => (
  //   <ListItem bottomDivider>
  //     {/* <Avatar source={{uri: item.avatar_url}} /> */}
  //     <ListItem.Content>
  //       <ListItem.Title>{item.barcode}</ListItem.Title>
  //       {/* <ListItem.Subtitle>{item.barcode}</ListItem.Subtitle> */}
  //       <Barcode value={item.barcode} />
  //     </ListItem.Content>
  //     <ListItem.Chevron />
  //   </ListItem>
  // );

  const render_quick_options_buttons = (button, key) => {
    return (
      <View>
        <Button
          key={key}
          style={styles.button2}
          title={button.button_name}
          buttonStyle={button.style}
          type={button.type}
          onPress={() => {
            changeStatus(
              navigation,
              base_url,
              driver_id,
              user.contact_id,
              button.status_code,
              saveLocation,
              location
            );
          }}
        />
      </View>
    );
  };

  return (
    <View style={{ flex: 1, flexDirection: "column" }}>
      <StatusBar hidden={true} />

      <Header
        placement="left"
        centerComponent={{ text: user.name.first, style: { color: "#fff" } }}
        rightComponent={
          <Button
            title="EDIT"
            onPress={() => {
              navigation.navigate("EditContact", {
                contact_id: user.contact_id,
              });
            }}
            color={"red"}
            buttonStyle={{ backgroundColor: "yellow" }}
          />
        }
      />

      <View style={{ alignItems: "center" }}>
        {/* TODO: manage empty barcode. */}
        {user.barcode != null ? (
          <Barcode value={user.barcode} format="CODE128" />
        ) : (
          <Text h3>No Barcode</Text>
        )}

        <Text h1>{user.barcode}</Text>
      </View>

      <View
        style={{
          flex: 1,
          flexDirection: "column",
          justifyContent: "space-between",
          alignSelf: "center",
          flexWrap: "wrap",
        }}
      >
        {button_list.map((button, i) =>
          render_quick_options_buttons(button, button.status_code)
        )}
      </View>

      <View></View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  map: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height * 0.9,
  },
  button2: {
    flex: 1,
    alignItems: "center",
    marginTop: 20,
    backgroundColor: "black",
    padding: 10,
    paddingHorizontal: 10,
    opacity: 0.8,
    borderRadius: 50,
    height: 100,
  },
});
