import * as React from "react";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
} from "@expo/vector-icons";
import { NavigationContainer } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { StuffinaboxProvider } from "./screens/redux/something_context";
import TodayScreen from "./screens/DeliveriesScreen";
import ContactInfo from "./screens/ManageParselScreen";
// Delivered Screen
import DeliveredScreen from "./screens/DeliveredScreen";
// Pickup Screen
import PickupScreen from "./screens/pickup/PickupsScreen";
import ManagePickupScreen2 from "./screens/pickup/ManagePickupScreen2";
import ManagePickupScreen2rest from "./screens/pickup/ManagePickupScreen2rest";
import ManagePickupScreen from "./screens/pickup/ManagePickupScreen";
// Deliveries Screen
import ManageContactScreenCreate from "./screens/ManageContactScreenCreate";

import ManageVilageScreen from "./screens/ManageVilage";
import { SafeAreaProvider } from "react-native-safe-area-context";
import ManageContactScreenEdit from "./screens/ManageContactScreenEdit";
// import * as Sentry from "sentry-expo";
// Sentry.init({
//   dsn:
//     "https://ec1f7ea9be144ba6b24493b1e843a91e@o1227578.ingest.sentry.io/6384607",
//   enableInExpoDevelopment: true,
//   debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
// });

import LoginScreen from "./screens/LoginScreen";

const Tab = createBottomTabNavigator();

const App = ({ navigation }) => {
  return (
    <NavigationContainer>
      <Tab.Navigator initialRouteName="Login">
        <Tab.Screen
          name="Login"
          component={LoginScreen}
          options={{ tabBarButton: () => null, tabBarVisible: false }}
        />
        <Tab.Screen
          name="Today"
          component={TodayScreen}
          options={{
            tabBarIcon: ({ tintColor }) => (
              <MaterialCommunityIcons
                name="truck-delivery-outline"
                size={30}
                color="black"
              />
            ),
          }}
        />

        <Tab.Screen
          name="Delivered"
          component={DeliveredScreen}
          options={{
            tabBarIcon: ({ tintColor }) => (
              <MaterialIcons name="done" size={30} color="black" />
            ),
          }}
        />
        <Tab.Screen
          name="ContactInfo"
          component={ContactInfo}
          // tabBarVisible={false}
          options={{ title: "My Info", tabBarButton: () => null }}
        />
        <Tab.Screen
          name="Pickup"
          component={PickupScreen}
          options={{
            tabBarIcon: ({ tintColor }) => (
              <Feather name="package" size={30} color="black" />
            ),
          }}
        />

        <Tab.Screen
          name="ManagePickup"
          component={ManagePickupScreen}
          // tabBarVisible={false}
          options={{ title: "My Pickups", tabBarButton: () => null }}
        />
        <Tab.Screen
          name="CreateContact"
          component={ManageContactScreenCreate}
          // tabBarVisible={false}
          options={{ title: "Create Contact", tabBarButton: () => null }}
        />

        <Tab.Screen
          name="EditPickup"
          component={ManagePickupScreen2}
          // tabBarVisible={false}
          options={{ title: "My Pickups", tabBarButton: () => null }}
        />

        <Tab.Screen
          name="EditPickup2"
          component={ManagePickupScreen2rest}
          // tabBarVisible={false}
          options={{ title: "My Pickups2", tabBarButton: () => null }}
        />
        <Tab.Screen
          name="EditContact"
          component={ManageContactScreenEdit}
          // tabBarVisible={false}
          options={{ title: "EditContact", tabBarButton: () => null }}
        />

        <Tab.Screen
          name="Manage Vilage"
          component={ManageVilageScreen}
          // tabBarVisible={false}
          options={{ title: "Vilage Manager", tabBarButton: () => null }}
        />
      </Tab.Navigator>
    </NavigationContainer>
  );
};

// 4755187fcac9464184e0cd9f00f637828e3762028bc84f7ca254d50b33192e03

export default () => {
  return (
    <SafeAreaProvider>
      <StuffinaboxProvider>
        <App />
      </StuffinaboxProvider>
    </SafeAreaProvider>
  );
};
