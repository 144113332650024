// import * as React from 'react';
import React, { useState } from "react";

import { Button, Header } from "react-native-elements";
import { StyleSheet, Dimensions } from "react-native";
import { View } from "react-native";

import base_url from "../config";

import { useContext } from "react";
import StuffinaboxContext from "./redux/something_context";

export default function ManageVilageScreen({ navigation, route }) {
  const { vilage } = route.params;
  const { driver_id, update_driver_id } = useContext(StuffinaboxContext);

  const delete_vilage = () => {
    // console.log("VILAGE: ");
    const base_domain = base_url + `/api/v2/contact/vilage/${vilage}`;
    const params_auth = "?token=" + driver_id;
    const url = base_domain + params_auth;
    fetch(url, { method: "DELETE" })
      .then((res) => res.json())
      .then((res) => {
        navigation.navigate("Today");
      })
      .catch((error) => {
        // console.log("WE HAVE AN ERROR");
      });
  };
  return (
    <View style={{ flex: 1 }}>
      <Header
        placement="center"
        centerComponent={{
          text: "Managing Vilages",
          style: { fontSize: 20, color: "#fff" },
        }}
      />
      <View style={{ alignItems: "center" }}>
        <Button
          title={"DELETE VILAGE"}
          buttonStyle={{ backgroundColor: "red" }}
          onPress={() => delete_vilage()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  map: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height * 0.9,
  },
  header: {
    fontSize: 32,
    backgroundColor: "#fff",
  },
  header: {
    fontSize: 32,
    backgroundColor: "#fff",
    alignContent: "center",
  },
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
});
