import React, { useState } from "react";
import { StatusBar } from "expo-status-bar";

import { Button, Header } from "react-native-elements";
import { StyleSheet, Dimensions, SectionList } from "react-native";
import { View } from "react-native";
import { ListItem, Text, Icon } from "react-native-elements";

import { useFocusEffect } from "@react-navigation/native";
import base_url from "../../config";
import { Linking } from "react-native";
import button_list from "../../buttonStatusList";

import Spinner from "react-native-loading-spinner-overlay";

import { useContext } from "react";
import StuffinaboxContext from "../redux/something_context";

export default function PickupScreen({ navigation }) {
  const { driver_id, update_driver_id } = useContext(StuffinaboxContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  useFocusEffect(
    React.useCallback(() => {
      // console.log("ENTERING TODAY DELIVERIES SCREEN");

      makeRemoteRequest(); // page => 1
      // makeProgressRequest(); // page => 1

      // Do something when the screen is focused
      return () => {
        // console.log("EXITING TODAY DELIVERIES SCREEN");
      };
    }, [navigation])
  );

  const makeRemoteRequest = () => {
    const base_domain = base_url + "/api/v2/pickup";
    const query_params = "?section=1";
    const params_auth = "&token=" + driver_id;
    const url = base_domain + query_params + params_auth;
    setLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        // console.log("GETTING DATA");
        setData(res);
        setLoading(false);
        setRefreshing(false);
        setError(res.error || null);
        // console.log("DONE");
      })
      .catch((error) => {
        // console.log("WE HAVE AN ERROR");
        setLoading(false);

        setError(error);
      });
  };

  const gotopackages = (item) => {
    navigation.navigate("EditPickup2", {
      user: item,
      packages: item.packages,
      contact_id: item.user_id,
      old_name: item.name.first,
      old_vilage: item.vilage,
      old_phone: item.cell,
      old_comment: item.comment,
      old_checked: item.status,
    });
  };

  const setTextColor = (item) => {
    // // console.log('settext color');
    let ColorValue = "transparent";
    if (item.status == 0) {
      // // console.log('delivered',item.delivered)
      ColorValue = "transparent";
    }
    if (item.status == 1) {
      ColorValue = "red";
    }
    if (item.status == 2) {
      ColorValue = "#4e89cc";
    }
    if (item.status == 3) {
      ColorValue = "green";
    }

    if (item.status == 4) {
      ColorValue = "pink";
    }
    return ColorValue;
  };

  const statusWord2 = (item) => {
    // the code you're looking for
    var word = "No Comment";

    // iterate over each element in the array
    for (var i = 0; i < button_list.length; i++) {
      // look for the entry with a matching `code` value
      if (button_list[i].status_code == item.status) {
        // // console.log('Searching')
        // we found it
        // obj[i].name is the matched result
        word = button_list[i].button_name;
      }
    }
    return word;
  };

  const statusColor = (item) => {
    // the code you're looking for
    var ColorValue = "transparent";

    // iterate over each element in the array
    for (var i = 0; i < button_list.length; i++) {
      // look for the entry with a matching `code` value
      if (button_list[i].status_code == item.status) {
        ColorValue = button_list[i].style.backgroundColor;
      }
    }
    return ColorValue;
  };

  const renderItem = ({ item }) => {
    return (
      <ListItem bottomDivider onPress={() => gotopackages(item)}>
        <Icon
          size={50}
          name={"phone"}
          disabled={!item.has_phone_number}
          onPress={() => makeCall(item.cell)}
        />

        <ListItem.Content style={{ backgroundColor: statusColor(item) }}>
          <ListItem.Title style={{ fontWeight: "bold" }}>
            {item.name.first}
          </ListItem.Title>
          <ListItem.Subtitle>COST: {item.cost}</ListItem.Subtitle>
          <ListItem.Subtitle>PHONE:{item.cell}</ListItem.Subtitle>
          <ListItem.Subtitle>COMMENT: {item.comment}</ListItem.Subtitle>
          <ListItem.Subtitle> STATUS: {statusWord2(item)}</ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Chevron />
      </ListItem>
    );
  };

  const onRefresh = () => {
    makeRemoteRequest();
    // makeProgressRequest();
  };

  const makeCall = (tel) => {
    Linking.openURL(`tel:${tel}`);
  };

  return (
    <View style={{ flex: 1 }}>
      <StatusBar hidden={true} />

      <Spinner visible={loading} textContent={"Loading..."} />
      <Header
        placement="center"
        // leftComponent={{ icon: 'menu', color: '#fff' }}
        // leftComponent={{ text: "Progress: " + progress + "%", color: "#fff" }}
        centerComponent={{
          text: "PENDING CALLS",
          style: { fontSize: 20, color: "#fff" },
        }}
        rightComponent={{
          icon: "refresh",
          color: "#fff",
          onPress: () => makeRemoteRequest(),
        }}
      />
      <View style={{ flex: 1 }}>
        <SectionList
          sections={data}
          // contentContainerStyle={{ paddingBottom: 60}}
          // initialNumToRender={10}
          // updateCellsBatchingPeriod={10}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          keyExtractor={(item, index) => item + index}
          renderItem={(item) => renderItem(item)}
          renderSectionHeader={({ section: { vilage } }) => (
            <Text style={styles.header}>{vilage}</Text>
          )}
        />
      </View>
      <View>
        <Button
          title={"NEW PICKUP"}
          color={"green"}
          onPress={() => navigation.navigate("ManagePickup")}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  map: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height * 0.9,
  },
  header: {
    fontSize: 32,
    backgroundColor: "#fff",
  },
  header: {
    fontSize: 32,
    backgroundColor: "#fff",
    alignContent: "center",
  },
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
});
